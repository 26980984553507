<script>
export default {
	lang: 'shop',
	props: {
		option: Object,
		currency: String,
		showToAddressLine: Boolean,
		showBeforePaymentMessages: Boolean,
		showAfterPaymentMessages: Boolean,
		showReceiver: Boolean,
		showCost: Boolean,
		showEta: Boolean,
		extraMessageItems: Array,
	},
	computed: {
		cMessageItems() {
			let items = []

			if (this.showEta && this.option.hasEta && this.option.etaMessage) {
				items.push({ icon: 'mdi-store-clock', text: this.option.etaMessage })
			}

			if (this.showToAddressLine) {
				items.push({ icon: 'mdi-map-marker', text: this.option.toAddressLine })
			}

			if (this.option.data?.messages?.length) {
				let _items = this.option.data.messages.map((text) => ({ icon: 'mdi-information', text }))
				items.push(..._items)
			}

			if (this.showBeforePaymentMessages && this.option.data?.beforePaymentMessages?.length) {
				let _items = this.option.data.beforePaymentMessages.map((text) => ({
					icon: 'mdi-information',
					text,
				}))
				items.push(..._items)
			}

			if (this.showAfterPaymentMessages && this.option.data?.afterPaymentMessages?.length) {
				let _items = this.option.data.afterPaymentMessages.map((text) => ({
					icon: 'mdi-information',
					text,
				}))
				items.push(..._items)
			}

			if (this.extraMessageItems?.length) {
				items.push(...this.extraMessageItems)
			}

			if (this.option.message) {
				items.push({ icon: 'mdi-information', text: this.option.message })
			}

			if (this.showReceiver && this.option.receiverFirstname) {
				let text =
					this.option.methodKey == 'PickupPoint'
						? `Retira ${this.option.receiverFirstname} ${this.option.receiverLastname} (${this.option.receiverIdNumber})`
						: `Recibe ${this.option.receiverFirstname} ${this.option.receiverLastname} (${this.option.receiverIdNumber})`

				items.push({ icon: 'mdi-account', text })
			}

			items.forEach((item) => {
				item.text = Array.isArray(item.text) ? item.text : item.text ? [item.text] : null
			})

			return items.filter((item) => item.icon && item.text?.length)
		},
	},
}
</script>

<template>
	<div class="delivery-option-content">
		<div class="d-flex align-start flex-column flex-sm-row justify-sm-space-between">
			<div>
				<div class="font-weight-bold font-1 delivery-option-content__title">
					{{ option.optionName }}
				</div>
			</div>
			<div v-if="option.hasCost && showCost" class="py-2 py-sm-0 pl-sm-3 d-flex align-center">
				<div v-if="option.discount" class="pr-2">
					<del class="grey--text">
						<PriceText :amount="option.cost" :currency="currency" />
					</del>
				</div>
				<div v-if="option.total > 0">
					<PriceText :amount="option.total" :currency="currency" class="font-2 font-weight-bold" />
				</div>
				<div v-else>
					<div class="rounded white--text success d-inline-block px-2 font-1">
						{{ 'Gratis' | lang }}
					</div>
				</div>
			</div>
		</div>
		<div class="pt-1" v-if="cMessageItems.length">
			<div v-for="(item, i) of cMessageItems" :key="i" class="py-1 d-flex align-start">
				<v-icon left style="font-size: 20px">{{ item.icon }}</v-icon>
				<div>
					<div v-for="(text, j) of item.text" :key="j" class="preline" style="line-height: 20px">
						{{ text }}
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.delivery-option-content {
	&__title {
		line-height: 24px;
	}
}
</style>
