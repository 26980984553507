const sumBy = require('lodash/sumBy')
const fixed = (n, f = 2) => Number(n.toFixed(f)) || 0

function getResultsLines(order, linesProps = {}) {
	const itemsLine = {
		value: fixed(sumBy(order.items, 'initTotal')),
		...(linesProps.items || {}),
	}

	const deliveriesLines = (order.deliveries || [])
		.filter((delivery) => !!delivery.cost)
		.map((delivery) => ({
			text: `${delivery.methodName} - ${delivery.optionName}`,
			value: delivery.cost,
			...(linesProps.delivery || {}),
		}))

	const discountsLines = (() => {
		let lines = []
		let itemsBaseDiscountTotal = sumBy(order.items, 'discountTotal')
		if (itemsBaseDiscountTotal) {
			let names = order.items.reduce((arr, item) => {
				for (let name of item.discountsNames || []) {
					if (!arr.includes(name.text)) arr.push(name.text)
				}
				return arr
			}, [])
			if (linesProps.itemsBaseDiscount?.name) {
				if (!names.includes(linesProps.itemsBaseDiscount.name)) {
					names.push(linesProps.itemsBaseDiscount.name)
				}
			}

			lines.push({
				text: names.join(' + '),
				value: -itemsBaseDiscountTotal,
				...(linesProps.discounts || {}),
				...(linesProps.itemsBaseDiscount || {}),
			})
		}
		for (let discount of order.discounts) {
			let value = -(discount.itemsDiscount + discount.deliveryDiscount)
			if (value) lines.push({ text: discount.discountName, value, ...(linesProps.discounts || {}) })
		}
		return lines
	})()

	const surchargeLine = {
		value: fixed(sumBy(order.payments || [], 'surcharge')),
		...(linesProps.surcharge || {}),
	}

	const subtotalLine = {
		value: fixed(itemsLine.value + sumBy(deliveriesLines, 'value')),
		...(linesProps.subtotal || {}),
	}

	const totalLine = {
		value: order.total,
		...(linesProps.total || {}),
	}

	const lines = [itemsLine, ...deliveriesLines]
	if (deliveriesLines.length && (discountsLines.length || surchargeLine.value) && linesProps.showSubtotal) {
		lines.push(subtotalLine)
	}
	lines.push(...discountsLines)
	if (surchargeLine.value) lines.push(surchargeLine)
	lines.push(totalLine)
	return lines.filter((x) => !x.hide)
}

function getMetricWord(metric, qty) {
	let spl = metric.split('/').map((s) => s.trim())
	if (qty == 1) return spl[0]
	else return `${spl[0]}${spl[1] || ''}`
}

function getJoinedItems(order) {
	for (let item of order.items) {
		item._key = `${item.refType}|${item.refId}|${item.customization?.id || ''}`
		item.unitMetricWord = getMetricWord(item.unitMetric, item.qty)
	}
	let jItems = order.items.reduce((arr, item) => {
		let key = item._key
		let jItem = arr.find((jItem) => jItem.key == key)
		if (!jItem) {
			jItem = { key, items: [] }
			arr.push(jItem)
		}
		jItem.items.push(item)
		return arr
	}, [])

	for (let jItem of jItems) {
		jItem.totalQty = fixed(sumBy(jItem.items, 'qty'))
		jItem.totalPackQty = fixed(sumBy(jItem.items, 'packQty'))
		jItem.finalTotal = fixed(sumBy(jItem.items, 'finalTotal'))
		jItem.finalDiscountTotal = fixed(sumBy(jItem.items, 'finalDiscountTotal'))
		jItem.surcharge = jItem.items[0].surcharge === null ? null : fixed(sumBy(jItem.items, 'surcharge'))
		jItem.unitMetricWord = getMetricWord(jItem.items[0].unitMetric, jItem.totalQty)
		jItem.packMetricWord = jItem.items[0].packMetric
			? getMetricWord(jItem.items[0].packMetric, jItem.totalPackQty)
			: null
		jItem.finalPricesGroups = jItem.items.reduce((groups, item) => {
			let group = groups.find((group) => group.finalPrice == item.finalPrice)
			if (!group) {
				groups.push({ finalPrice: item.finalPrice, initPrice: item.initPrice, qty: item.qty })
			} else {
				group.qty += item.qty
			}
			return groups
		}, [])
	}

	return jItems
}

function makeSummary(order) {
	return {
		get currency() {
			return order.currency.sign
		},
		getItems() {
			return order.items
		},
		getJoinedItems() {
			return getJoinedItems(order)
		},
		getResultsLines(resultsLinesProps) {
			return getResultsLines(order, resultsLinesProps)
		},
	}
}

module.exports = makeSummary
module.exports.default = makeSummary

