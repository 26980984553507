<script>
import hooksMixin from '@/v-shop/mixins/hooks-mixin'

export default {
	mixins: [hooksMixin('OrderSummaryItem')],
	lang: 'shop',
	props: {
		order: Object,
		joinedItem: Object,
	},
	computed: {
		currency() {
			return this.order.currency.sign
		},
		item() {
			return this.joinedItem.items[0]
		},
	},
}
</script>

<template>
	<div class="d-flex align-start px-2 py-4 px-sm-2">
		<div class="item-image-cont flex-shrink-0">
			<Media
				:src="item.image"
				aspect-ratio="1"
				width="100%"
				img-class="rounded"
				class="rounded item-image"
			/>
		</div>
		<div class="flex-shrink-1 px-2 px-sm-4">
			<hook zone="name">
				<div class="font-weight-bold line-clamp-2 font-1 font-sm-2">
					{{ item.name }}
				</div>
			</hook>

			<div v-if="item.descriptions">
				{{ item.descriptions.map((e) => e.text).join(' | ') }}
			</div>
			<div class="grey--text font-0 mt-1">SKU {{ item.sku }}</div>
			<div>
				<div v-for="(group, i) of joinedItem.finalPricesGroups" :key="i" class="d-flex align-center pt-1">
					<div class="grey--text font-1 pr-2" v-if="joinedItem.finalPricesGroups.length > 1">
						{{ group.qty }}x
					</div>
					<ProductPrice
						:amount="group.finalPrice"
						:currency="currency"
						custom-class="black--text font-2"
					/>
					<ProductPrevPrice
						v-if="group.initPrice > group.finalPrice"
						:amount="group.initPrice"
						:currency="currency"
						customClass="font-0 pl-2 grey--text"
					/>
				</div>
			</div>
			<hook zone="below-price" />
			<!-- <div class="text-body-2 pt-1" v-if="item.discount">
				<span>{{ item.discountsNames.map((e) => e.text).join(' + ') }}</span>
				<ProductDiscount :amount="item.discountPct" class="ml-1" />
			</div> -->
		</div>
		<v-spacer />
		<div>
			<div class="text-right">
				<PriceText
					class="font-weight-bold font-2 font-sm-3 d-inline"
					:amount="joinedItem.finalTotal"
					:currency="currency"
				/>
			</div>
			<div class="text-caption nowrap text-right">
				{{ joinedItem.totalQty }} {{ joinedItem.unitMetricWord }}
			</div>
			<div class="text-caption nowrap text-right grey--text" v-if="joinedItem.packMetricWord">
				{{ joinedItem.totalPackQty }} {{ joinedItem.packMetricWord }}
			</div>

			<div class="text-right" v-if="joinedItem.finalDiscountTotal">
				<div class="discount px-2 font-0 rounded d-inline-block py-1 nowrap" style="line-height: 1">
					{{ Math.floor(joinedItem.finalDiscountTotal) | price({ currency, decimalCount: 0 }) }}
					{{ 'Ahorro' | lang }}
				</div>
			</div>

			<div v-if="joinedItem.surcharge !== null" class="text-right">
				<div
					class="px-2 font-0 rounded-lg d-inline-block py-1 nowrap"
					style="line-height: 1"
					:class="{
						'success ': !joinedItem.surcharge,
					}"
				>
					<span v-if="joinedItem.surcharge">
						+ {{ Math.floor(joinedItem.surcharge) | price({ currency, decimalCount: 0 }) }}
						{{ 'recargo' | lang }}
					</span>
					<span class="text-uppercase" v-else>{{ 'sin interés' | lang }}</span>
				</div>
			</div>
		</div>
	</div>
</template>

<style scoped>
.item-image {
	border: 1px solid #ddd;
}
.item-image-cont {
	width: 70px;
}
</style>

