<script>
export default {
	inheritAttrs: false,
	props: {
		option: Object,
	},
}
</script>

<template>
	<DeliveryOptionContent
		v-on="$listeners"
		v-bind="$attrs"
		:option="option"
		:extra-message-items="[
			{ icon: 'mdi-clock', text: option.data.openingHours },
			{ icon: 'mdi-phone', text: option.data.phone },
			{ icon: 'mdi-email', text: option.data.email },
		]"
		show-to-address-line
	/>
</template>
